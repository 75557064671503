<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6">
            <date-component v-model="date_start" req label="Дата початку"/>
          </v-col>
          <v-col cols="6">
            <date-component v-model="date_end" label="Дата закінчення"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled label="Тариф"
                          v-model="tariff"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="tariff ? '' : 'req-star'"
                          color="grey"
                          v-decimal
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ALERT_SHOW} from "@/store/actions/alert";
import {
  CREATE_PENALTY_TARIFF,
  UPDATE_PENALTY_TARIFF,
  DELETE_PENALTY_TARIFF
} from "@/store/actions/penalty_tariff";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'penalty_tariff_modal_id'

export default {
  name: "HWP_Modal_PenaltyTariff",
  mixins: [ModalComponentMixin],
  components: {
  },
  data() {
    return {
      is_legacy: this.item.is_legacy || false,
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      tariff: this.item.value_dec || 0,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.is_legacy = this.item.is_legacy || false
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.tariff = this.item.tariff

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false

      const payload = {
        text: `Підтвердіть вилучення тарифу пені: ${this.tariff}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false

        this.$store.commit(ALERT_SHOW, {
          text: `Дата початку та тариф мають бути заповнені`,
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        is_legacy: this.is_legacy || false,
        date_start: this.date_start,
        date_end: this.date_end,
        tariff: this.tariff,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_PENALTY_TARIFF, payload)
            .then(() => {
              this.$store.commit(ALERT_SHOW,
                  {
                    text: 'Тариф створено успішно. Виконайте загальний розрахунок по усіх абонентах',
                    color: 'success'
                  })
              this.closeModal()
            })
      } else {
        payload['id'] = this.itemId

        this.$store.dispatch(UPDATE_PENALTY_TARIFF, payload)
            .then(() => {
              this.$store.commit(ALERT_SHOW,
                  {
                    text: 'Тариф оновлено успішно. Виконайте загальний розрахунок по усіх абонентах',
                    color: 'success'
                  })
              this.closeModal()
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.is_legacy = payload.is_legacy || false
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.tariff = payload.tariff
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_PENALTY_TARIFF, this.itemId)
                      .then(() => {
                        this.$store.commit(ALERT_SHOW,
                            {
                              text: 'Тариф вилучено успішно. Виконайте загальний розрахунок по усіх абонентах',
                              color: 'success'
                            })
                        this.closeModal()
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
